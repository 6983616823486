import { Link } from 'react-router-dom'
import github from '../../../assets/github.png'
import data from '../../../data/translations';



import './ProjectCard.scss';

const ProjectCard = ({ project, lang, done, id }) => {
    return (
        <div className="projects-card">
            <h3 className="card-language">// {project.language} //</h3>
            <h4 className="card-title">{project.title}</h4>
            <Link to={`/details/${id}`}>
                <img className="card-picture" src={project.images[0]}></img>
            </Link>
            <p className="card-description">{project.description.description_short}</p>
            <section className="card-tags">
                <ul className="techno-tags">
                    {(project.technos.technos_main).map((techno, index) => {
                        return <li className="techno-tag" key={index}>{techno}</li>
                    })}
                </ul>
                <div className="details-tags">
                    <Link to={`/details/${id}`}>
                        <div className="details-tag details">{data[lang].projects.details}</div>
                    </Link>
                    {
                        done 
                            ?
                            <a href={project.link} target="_blank" rel="noreferrer">
                                <div className="details-tag link-tag">Demo</div>
                            </a>
                            :
                            ''

                    }
                    <a href={project.github} target="_blank" rel="noreferrer">
                        <img className="details-tag github-tag" src={github} alt="Lien du dépôt Github" />
                    </a>
                </div>
            </section>
        </div>
    )
};

export default ProjectCard;
