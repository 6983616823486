const translations = {
    FR: {
        home: {
            welcome: "Bienvenue sur mon site !",
            description: "Naomi Legentil - Développeuse front-end Angular, React, JavaScript et TypeScript",
            look: "Jetez un oeil à mes projets"
        },
        header: {
            projects: "Projets",
            whoami: "Qui suis-je ?",
            technos: "Technos"
        },
        language: {
            select: "Choisissez une langue"
        },
        projects: {
            not_done: {
                title: "Projets en cours",
            },
            done: {
                title: "Projets réalisés",
            },
            details: "Détails +"
        },
        whoami: {
            title: "Qui suis-je ?",
            bio: "Passionnée par le développement web front-end, j’ai tenu à intégrer des formations sélectives et professionnalisantes pour acquérir rapidement les bonnes pratiques sans perdre de vue la réalité professionnelle du métier de développeuse. Ma détermination est un atout considérable dans ma recherche d’une nouvelle opportunité professionnelle, et j’espère pouvoir mettre mon esprit d’équipe et ma capacité d’analyse au service de l’entreprise qui me donnera ma chance.",
            cv: "Mon CV",
            formation: "Parcours",
            formations_array: [
                {
                    logo: "https://iili.io/H8VDgQR.png",
                    url: "https://www.groupe-creative.fr",
                    beginning: "2023 - janvier",
                    end: "2023 - mai",
                    name: "Groupe Creative",
                    description: `Poste de Développeuse Front-End (Angular) <br/>
                Après la validation avec honneur de ma formation chez Zenika Rennes, j'ai entamé mon expérience de développeuse front-end chez Groupe Creative à Rennes. Je me suis enrichie du savoir-faire de mes collègues et des conditions de travail d'une ESN. <br/>
                <br/>
                Projet d'entreprise:<br/>
                Développement d'un projet interne à l'entreprise en Angular qui permet de simplifier le suivi des collaborateurs par la direction, les managers et les commerciaux. Il permet à tous les collaborateurs d’avoir accès aux entretiens annuels, suivis de missions, formations… La nouvelle version embarque de nouvelles fonctionnalités telles que les suggestions de formations, certifications et une nouvelle gestion de rôles. <br/>
                <br/>
                Travail réalisé: <br/>
                - Création et maintien de différents composants, qui sont pour la majorité des formulaires. <br/>
                - Création de composants réutilisables et utilisation de SCSS afin de clarifier et optimiser le projet. <br/>
                - Utilisation d’Angular Router, des directives et observables <br/>
                - Résolution d’erreurs SonarQube <br/>
                - Workflow Scrumban (mélange des méthodologies Scrum et Kanban) via des tickets Jira <br/>
                - Intégration front-end de maquettes Figma en relation avec des User Stories <br/>
                - Utilisation de containers Docker <br/>
                <br/>
                Taille de l’équipe : 9 développeurs, 3 testeurs, 2 PO, 1 Scrum Master.  `
                },
                {
                    logo: "https://iili.io/H8VDQvn.png",
                    url: "https://www.zenika.com",
                    beginning: "2022 - octobre",
                    end: "2023 - janvier",
                    name: "Zenika Rennes",
                    description: `POE-I de 3 mois encadrée par Pôle Emploi avec intention d'embauche chez Groupe Creative. <br/>
                    <br/>
                Objectifs de la formation:<br/>
                - Apprentissage de TypeScript, Angular et NestJS <br/>
                - Initiation à Docker et Docker Compose <br/>
                <br/>
                Projets pratiques: <br/>
                - Création d'un réseau social en Angular et NestJS <br/>
                - Création d'un CRUD back en NestJS (vérification et filtrage de données d'habitants) <br/>
                - Création d'un CRUD front en Angular (Gestion de bibliothèque de films) <br/>
                <br/>
                Projet final: Création de "Gazouilleur", le Twitter français en Angular/NestJS et une base de données en MongoDB. Connexion, rédaction de tweets, likes et commentaires.`
                },
                {
                    logo: "https://enpantoufles.com/uploads/companies/1560256962_Picto%20noir%20+%20vert%201667x1667.png",
                    url: "https://oclock.io",
                    beginning: "2021 - février",
                    end: "2021 - août",
                    name: "O'Clock",
                    description: `Formation de 798 heures à temps plein (35 heures par semaine) <br/>
                Objectifs de la formation:<br/>
                <br/>
                Front-end: <br/>
                - Maquetter une application <br/> 
                - Réaliser une interface utilisateur web statique et adaptable <br/>
                - Développer une interface utilisateur web dynamique <br/>
                <br/>
                Back-end: <br/>
                - Créer une base de données <br/>
                - Développer les composants d'accès aux données <br/>
                - Développer la partie back-end d'une application web ou web mobile <br/>
                <br/>
                Projet final: Création d'un site de blind test en React avec l'API de Deezer pour la recherche et lecture de musiques, gestion de connexions, scores et CRUD de playlists.`
                },
                {
                    logo: "https://pbs.twimg.com/profile_images/1464245558500020225/BmS__WxI_400x400.jpg",
                    url: "https://www.univ-rennes2.fr",
                    beginning: "2019",
                    end: "2020",
                    name: "Université de Rennes 2",
                    description: "1ère année de licence de Langues, Littératures, Civilisations Étrangères et Régionales (LLCER)"
                }
            ],

        },
        technos: {
            title: "Technos",
            frontend: {
                title: "// FRONT-END //",
                description: `- HTML <br/>
                - CSS <br/>
                - JavaScript <br/>
                - TypeScript <br/>
                - React <br/>
                - React Redux <br/>
                - Angular <br/>`
            },
            backend: {
                title: "// BACK-END //",
                description: `Notions en Node.JS et Express pour la création et gestion de serveurs <br/>
                <br/>
                Notions en NestJS pour la création de back-end couplé à Angular, avec documentation en Swagger <br/>
                <br/>
                Création de bases de données SQL avec PostgreSQL <br/>
                <br/>
                Utilisation de bases de données NoSQL avec MongoDB <br/>
                <br/>
                Création et utilisation de base de données NoSQL et sans gestion de serveurs avec Google Firestore`
            },
            deployment: {
                title: "// DÉPLOIEMENT //",
                description: `- Netlify <br/>
                - Github Pages`
            },
            ux: {
                title: "// UX-UI //",
                description: `Création des wireframes avec Whimsical et Figma <br/>
                <br/>
                Création des maquettes et design avec Figma`
            },
            tools: {
                title: "// OUTILS //",
                description: `Rédaction des users stories, cahier des charges et fonctionnalités de l’application sur Notion <br/>
                <br/>
                Division de gros projets en sprints sur Github <br/>
                <br/>
                Utilisation de Git pour le versioning des projets <br/>
                <br/>
                Résolutions de problèmes avec Stackoverflow`
            }
        }
    },
    EN: {
        home: {
            welcome: "Welcome to my website!",
            description: "Naomi Legentil - Front-end Angular, React, JavaScript and TypeScript developer",
            look: "Have a look at my projects"
        },
        header: {
            projects: "Projects",
            whoami: "Who am I?",
            technos: "Technos"
        },
        language: {
            select: "Choose a language"
        },
        projects: {
            not_done: {
                title: "Ongoing projects",
            },
            done: {
                title: "Completed projects",
            },
            details: "Details +"
        },
        whoami: {
            title: "Who am I?",
            bio: "Passionate about front-end web development, I made sure to enroll in selective and professionalizing courses to quickly acquire best practices while keeping a realistic view of the professional reality of being a developer. My determination is a significant asset in my search for a new professional opportunity, and I hope to be able to contribute my teamwork and analytical skills to the company willing to give me a chance.",
            cv: "My CV",
            formation: "Career",
            formations_array: [
                {
                    logo: "https://iili.io/H8VDgQR.png",
                    url: "https://www.groupe-creative.fr",
                    beginning: "2023 - January",
                    end: "2023 - May",
                    name: "Groupe Creative",
                    description: `Front-end web developer (Angular) <br/>
                    After successfully completing my training at Zenika Rennes, I embarked on my front-end
                    developer experience at Groupe Creative in Rennes. I benefited from the expertise of my colleagues and gained valuable insights into the working conditions of an IT consulting company. <br/>
                <br/>
                Company project:<br/>
                Development of an internal project using Angular, which aims to streamline the tracking of employees by the management, managers, and sales teams. It allows all employees to have access to annual reviews, mission tracking, training, etc. The new version includes additional features such as training suggestions, certifications, and an updated role management system. <br/>
                <br/>
                Work done: <br/>
                - Creating and maintaining various components, including forms. <br/>
                - Developing reusable components and utilizing SCSS to streamline and optimize the project. <br/>
                - Implementing Angular Router, directives, and observables. <br/>
                - Resolving SonarQube errors. <br/>
                - Following a Scrumban workflow (a combination of Scrum and Kanban methodologies) using Jira tickets. <br/>
                - Front-end integration of Figma mockups in alignment with User Stories. <br/>
                - Use of Docker containers. <br/>
                <br/>
                Team size : 9 developers, 3 testers, 2 Product Owners, 1 Scrum Master.  `
                },
                {
                    logo: "https://iili.io/H8VDQvn.png",
                    url: "https://www.zenika.com",
                    beginning: "2022 - October",
                    end: "2023 - January",
                    name: "Zenika Rennes",
                    description: `3-month FullStack TypeScript web development training (Angular/NestJS) so as to work for Groupe Créative. <br/>
                    <br/>
                Training goals: <br/>
                - Final Grade: 16.36/20 <br/>
                - Learning TypeScript, Angular, and NestJS <br/>
                - Introduction to Docker and Docker Compose <br/>
                <br/>
                Training projects: <br/>
                - Creating a social network with Angular and NestJS <br/>
                - Creating a back-end CRUD in NestJS (verification and filtering of resident data) <br/>
                - Creating a front-end CRUD in Angular (movie library management) <br/>
                <br/>
                Final project: Creation of "Gazouilleur", the French Twitter, using Angular/NestJS and a MongoDB database. User connection, writing tweets, likes and comments.`
                },
                {
                    logo: "https://enpantoufles.com/uploads/companies/1560256962_Picto%20noir%20+%20vert%201667x1667.png",
                    url: "https://oclock.io",
                    beginning: "2021 - February",
                    end: "2021 - August",
                    name: "O'Clock",
                    description: `5-month full-time training (35 hours per week) with a focus on React.<br/>
                Goals of the course: <br/>
                <br/>
                Front-end: <br/>
                - Prototyping an application <br/>
                - Creating a static and responsive web user interface <br/>
                - Developing a dynamic web user interface <br/>
                <br/>
                Back-end: <br/>
                - Creating a database <br/>
                - Developing data access components <br/>
                - Developing the back-end part of a web or mobile web application <br/>
                <br/>
                Final Project: Creating a blind test website in React using the Deezer API for music search and playback, managing connections, scores, and CRUD operations for playlists.`
                },
                {
                    logo: "https://pbs.twimg.com/profile_images/1464245558500020225/BmS__WxI_400x400.jpg",
                    url: "https://www.univ-rennes2.fr",
                    beginning: "2019",
                    end: "2020",
                    name: "Université de Rennes 2",
                    description: "First year of an English degree (LLCER Anglais)"
                }
            ],
        },
        technos: {
            title: "Technos",
            frontend: {
                title: "// FRONT-END //",
                description: `- HTML <br/>
                - CSS <br/>
                - JavaScript <br/>
                - TypeScript <br/>
                - React <br/>
                - React Redux <br/>
                - Angular <br/>`
            },
            backend: {
                title: "// BACK-END //",
                description: `Notions in Node.JS and Express to create and manage servers <br/>
                <br/>
                Creating NestJS back-end paired with Angular, with Swagger for documentation <br/>
                <br/>
                SQL databases creation with PostgreSQL <br/>
                <br/>
                Use of NoSQL databases with MongoDB <br/>
                <br/>
                Creation and use of serverless NoSQL databases with Google Firestore`
            },
            deployment: {
                title: "// DEPLOYMENT //",
                description: `- Netlify <br/>
                - Github Pages`
            },
            ux: {
                title: "// UX-UI //",
                description: `Wireframe creation with Whimsical and Figma <br/>
                <br/>
                Model and design creation with Figma`
            },
            tools: {
                title: "// TOOLS //",
                description: `User stories creation, specifications and application functionalities on Notion <br/>
                <br/>
                Division of big projects in sprints on Github <br/>
                <br/>
                Use of Git for project versionings <br/>
                <br/>
                Problem solving with Stackoverflow`
            }
        }
    }
}

export default translations;