import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify';
import { Context } from '../../context/langContext'
import data from '../../data/translations';

import './Technologies.scss';
import home from '../../assets/home.svg';

const Technologies = () => {
    const { lang } = useContext(Context);

    return (
        <motion.div className="technos-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            <div className="technos-list-container">
                <h2>{data[lang].technos.title}</h2>
                <div className="technos-list">
                    <div className="technos-card">
                        <h3 className="techno-title">
                            {data[lang].technos.frontend.title}
                        </h3>
                        <p className="techno-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[lang].technos.frontend.description) }}>
                        </p>
                    </div>

                    <div className="technos-card">
                        <h3 className="techno-title">
                            {data[lang].technos.backend.title}
                        </h3>
                        <p className="techno-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[lang].technos.backend.description) }}>
                        </p>
                    </div>

                    <div className="technos-card">
                        <h3 className="techno-title">
                            {data[lang].technos.deployment.title}
                        </h3>
                        <p className="techno-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[lang].technos.deployment.description) }}>
                        </p>
                    </div>

                    <div className="technos-card">
                        <h3 className="techno-title">
                            {data[lang].technos.ux.title}
                        </h3>
                        <p className="techno-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[lang].technos.ux.description) }}>
                        </p>
                    </div>

                    <div className="technos-card">
                        <h3 className="techno-title">
                            {data[lang].technos.tools.title}
                        </h3>
                        <p className="techno-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[lang].technos.tools.description) }}>
                        </p>
                    </div>
                </div>
            </div>
            <nav className="home-arrow arrow-technos">
                <Link to="/">
                    <img src={home} alt="Retourner à l'accueil" />
                </Link>
            </nav>
        </motion.div>
    )
};

export default Technologies;
