import { createContext, useState } from 'react';

export const Context = createContext();

const supportedLangs = ['FR', 'EN'];
// getting the browser language and slicing the first three characters (ie: fr-)
let browserLang = navigator.language.slice(0, 2).toUpperCase();

// if the browser language is not supported, then the default language is English.
if (supportedLangs.indexOf(browserLang) === -1) {
    browserLang = 'EN';
}

const ContextProvider = props => {
    const [lang, setLang] = useState(browserLang);

    const toggleLang = selectedLang => {
        setLang(selectedLang)
    }

    return (
        <Context.Provider value={{ lang, toggleLang }}>
            {props.children}
        </Context.Provider>
    )
}

export default ContextProvider;
