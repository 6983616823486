import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Context } from '../../context/langContext'
import projectsData from '../../data/projectsData'

import './Details.scss';
import home from '../../assets/home.svg';
import DetailCard from './DetailCard/DetailCard';

const Details = () => {
    const { lang } = useContext(Context);
    const { id } = useParams();

    return (
        <motion.div className="details-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            {Object.entries(projectsData.done).map((project, index) => {
                if (project[1].id == id) {
                    return (
                        <DetailCard project={project[1][lang]} lang={lang} key={index}></DetailCard>
                    )
                }
                else return
            })}
            {Object.entries(projectsData.not_done).map((project, index) => {
                if (project[1].id == id) {
                    return (
                        <DetailCard project={project[1][lang]} lang={lang} key={index}></DetailCard>
                    )
                }
                else return
            })}
            <nav className="home-arrow arrow-details">
                <Link to="/">
                    <img src={home} alt="Retourner à l'accueil" />
                </Link>
            </nav>
        </motion.div>
    )
};

export default Details;
