import { motion } from 'framer-motion';
import data from '../../data/translations';

import './LanguageSelector.scss';
import france from '../../assets/france.png';
import uk from '../../assets/uk.png';
import close from '../../assets/close.png';

const LanguageSelector = ({ setSelectLanguage, selectLanguage, toggleLang, lang }) => {
    return (
        <>
            {selectLanguage &&
                <motion.div className="language-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.3 } }}>
                    <div className="language-block">
                        <img className="close" src={close} alt="Fermer" onClick={() => setSelectLanguage(!selectLanguage)} />
                        <h2>{data[lang].language.select}</h2>
                        <div className="language-options">
                            <div className="language-flag flag-french">
                                <img src={france} alt="Français" onClick={() => toggleLang('FR')} />
                                <span>FR</span>
                            </div>
                            <div className="language-flag flag-english">
                                <img src={uk} alt="Anglais" onClick={() => toggleLang('EN')} />
                                <span>EN</span>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </>
    )
};

export default LanguageSelector;
