import DOMPurify from 'dompurify';
import Carousel from 'framer-motion-carousel';
import github from '../../../assets/github.png'

import './DetailCard.scss';

const DetailCard = ({project, lang}) => {
    return (
        <div>
            <h2>{project.title}</h2>
            <div className="details-picture-container">
                <div className="details-picture">
                    <Carousel interval={2500}>
                        {project.images.map((img, i) => (
                            <img
                                src={img}
                                key={i}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: '5px',
                                    objectFit: 'contain'
                                }}
                                alt="carousel"
                            ></img>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="details-tags">
                { Object.keys(project.link).length > 2 
                    ?
                    <a href={project.link} target="_blank" rel="noreferrer">
                        <div className="details-tag link-tag" target="_blank" rel="noreferrer">{project.link}</div>
                    </a>
                    :
                    ''
                }
                <a href={project.github} target="_blank" rel="noreferrer">
                    <img className="details-tag github-tag" src={github} alt="Lien du dépôt Github" />
                </a>
            </div>
            <h3 className="details-description-header">{project.description.title}</h3>
            <p className="details-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project.description.description) }}></p>
            <h3 className="details-functionalities-header">{project.functionalities.title}</h3>
            <p className="details-functionalities" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project.functionalities.functionalities) }}></p>
            <h3 className="details-techno-header">{project.technos.title}</h3>
            <div className="details-techno-container">
                <div className="details-techno">
                    <h4>// {project.technos.frontend.title} //</h4>
                    <h5>{project.technos.frontend.language_title}</h5>
                    <ul>
                        {(project.technos.frontend.language).map((techno, index) => {
                            return <li className="details-techno-tag" key={index}>{techno}</li>
                        })}
                    </ul>
                    <h5>Packages</h5>
                    <ul>
                        {(project.technos.frontend.packages).map((techno, index) => {
                            return <li className="details-techno-tag" key={index}>{techno}</li>
                        })}
                    </ul>
                </div>
                <div className="details-techno">
                    <h4>// {project.technos.backend.title} //</h4>
                    <h5>{project.technos.backend.language_title}</h5>
                    <ul>
                        {(project.technos.backend.language).map((techno, index) => {
                            return <li className="details-techno-tag" key={index}>{techno}</li>
                        })}
                    </ul>
                    <h5>Packages</h5>
                    <ul>
                        {(project.technos.backend.packages).map((techno, index) => {
                            return <li className="details-techno-tag" key={index}>{techno}</li>
                        })}
                    </ul>
                </div>
                <div className="details-techno">
                    <h4>// {project.technos.deployment.title} //</h4>
                    <h5>Front-end</h5>
                    <ul>
                        <li className="details-techno-tag">{project.technos.deployment.frontend}</li>
                    </ul>
                    <h5>Back-end</h5>
                    <ul>
                        <li className="details-techno-tag">{project.technos.deployment.backend}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default DetailCard;
