import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ContextProvider from './context/langContext'
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Projects from './components/Projects/Projects';
import Details from './components/Details/Details';
import WhoAmI from './components/WhoAmI/WhoAmI';
import Technologies from './components/Technologies/Technologies';

function App() {
  const location = useLocation();
  const isHomePage = location.pathname === '/'

  return (
    <ContextProvider>
      <AnimatePresence exitBeforeEnter>
        {!isHomePage && <Header />}
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/details/:id" element={<Details />} />
          <Route path="/whoami" element={<WhoAmI />} />
          <Route path="/technos" element={<Technologies />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </ContextProvider>
  );
}

export default App;
