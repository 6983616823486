import { useContext } from 'react';
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Context } from '../../context/langContext'
import projectsData from '../../data/projectsData'
import data from '../../data/translations';

import './Projects.scss';
import home from '../../assets/home.svg'
import ProjectCard from './ProjectCard/ProjectCard';

const Projects = () => {
    const { lang } = useContext(Context);

    console.log(Object.keys(projectsData.not_done).length > -1)
    return (
        <motion.div className="projects-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            <div className="projects-list-container">
                {
                    Object.keys(projectsData.not_done).length > -1 
                        ?
                        <>
                            <h2>{data[lang].projects.not_done.title}</h2>
                            <div className="projects-list ongoing">
                                {Object.entries(projectsData.not_done).map((project, index) => {
                                    return (
                                        <ProjectCard project={project[1][lang]} lang={lang} done={false} key={index} id={project[1].id}></ProjectCard>
                                    )
                                })}
                            </div>
                        </>
                        :
                        ''
                }
                <h2>{data[lang].projects.done.title}</h2>
                            <div className="projects-list done">
                                {Object.entries(projectsData.done).map((project, index) => {
                                    return (
                                        <ProjectCard project={project[1][lang]} lang={lang} done={true} key={index} id={project[1].id}></ProjectCard>
                                    )
                                })}
                            </div>
            </div>
            <nav className="home-arrow arrow-projects">
                <Link to="/">
                    <img src={home} alt="Retourner à l'accueil" />
                </Link>
            </nav>
        </motion.div>
    )
};

export default Projects;
