const projectsData = {
    not_done: {
        meeet: {
            id: 1,
            FR: {
                title: "💜 Meeet 🫂",
                language: "REACT",
                images: ["https://iili.io/jCnEMu.png", "https://iili.io/jCntPs.png", "https://iili.io/jCnstt.png", "https://iili.io/jCnstt.png"],
                description: {
                    title: "Description",
                    description_short: "Meeet est une application de rencontre basée sur la geolocalisation qui permet aux utilisateurs de rencontrer des gens autours d'eux. ",
                    description: `Meeet est de loin le plus gros projet solo sur lequel j'ai travaillé. Meeet est une application de rencontre basée sur la geolocalisation qui permet aux utilisateurs de rencontrer des gens autours d'eux. Les utilisateurs peuvent voir les profils à travers une grille, le profil le plus proche étant le premier. </br> 
                    De plus, les utilisateurs peuvent être filtrés d'après leur âge, genre et ceux qu'ils recherchent. Les utilisateurs peuvent voir les profils des autres utilisateurs contenant leurs photos, nom, âge, description, intérêts et des informations générales à leur propos. En fonction de l'intérêt que porte un utilisateur envers un autre, il peut soit l'ajouter à ses favoris, commencer une conversation avec ou alors le bloquer.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `- Formulaire d’inscription et de connexion </br>
                    - Barre latérale de navigation sur bureau et tabbar sur mobiles: </br>
                    &emsp;   - Avatar et nom de l’utilisateur </br>
                    &emsp;    - ✏️ Modifier le profil (seulement sur bureau) </br>
                    &emsp;    - 💜 Accès aux favoris </br>
                    &emsp;    - 👓 Accès aux utilisateurs qui nous ont vu </br>
                    &emsp;    - 💬 Accès aux messages </br>
                    &emsp;    - ⚙️ Accès aux paramètres </br>
                    &emsp;    - Retour à l’accueil </br>
                    - Choisir sa localisation </br>
                    - Voir une grille avec les profils les plus près de nous </br>
                    - Voir les informations d’un profil: </br>
                    &emsp;    - Ses photos </br>
                    &emsp;    - Son nom </br>
                    &emsp;    - Son âge </br>
                    &emsp;    - Ses informations (description, centres d’intérêts et informations générales) </br>
                    - Filtrer les profils en fonction de l’âge voulu, du genre et des recherches des utilisateurs </br>
                    - Voir qui a visité notre profil </br>
                    - Discuter avec des utilisateurs </br>
                    - Gestion de profil: </br>
                    &emsp;    - Déconnexion </br>
                    &emsp;    - Suppression du compte </br>
                    &emsp;    - Changer d’email </br>
                    &emsp;    - Changer de mot de passe </br>
                    &emsp;    - Modifier son profil (photos, nom, âge, informations) </br>
                    &emsp;    - Ajouter un utilisateur aux favoris </br>
                    &emsp;    - Bloquer un utilisateur </br>
                    </br>
                    👉 Envie d’en savoir plus ? Accédez au dépôt GitHub pour une explication technique du projet.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["firebase", "redux", "react router dom"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["React"],
                        packages: ["axios", "redux toolkits", "react router dom", "react leaflet", "sass"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["noSQL"],
                        packages: ["Firebase Authentication", "Firestore"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "/",
                        backend: "Firebase"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/meeet-react-js"
            },
            EN: {
                title: "💜 Meeet 🫂",
                language: "REACT",
                images: ["https://iili.io/jCnEMu.png", "https://iili.io/jCntPs.png", "https://iili.io/jCnstt.png", "https://iili.io/jCnstt.png"],
                description: {
                    title: "Description",
                    description_short: "Meeet is a dating app based on geolocation which allows users to meet people around them.",
                    description: `Meeet is by far the biggest solo project I have ever worked on. Meeet is a dating app based on geolocation which allows users to meet people around them. Users can see profiles through a grid, the nearest profile being the first one. </br> 
                    Moreover, users can be filtered by their age, gender and what they are looking for. Users can see other users' profiles containing their pictures, name, age, description, interests and some general information about them. Following a user's interest for another user, they can either add them to favorites, start a conversation with them or block them.`
                },
                functionalities: {
                    title: "Functionalities",
                    functionalities: `- Sign up and sign in form </br>
                    - Lateral navigation bar on desktop and tabbar on mobile</br>
                    &emsp;   - Profile picture and user name </br>
                    &emsp;    - ✏️ Edit profile (only on desktop) </br>
                    &emsp;    - 💜 Access to favorites </br>
                    &emsp;    - 👓 Access to users who saw us </br>
                    &emsp;    - 💬 Access to messages </br>
                    &emsp;    - ⚙️ Access to settings </br>
                    &emsp;    - Back to the home page </br>
                    - Choosing our location </br>
                    - See a grid with the nearest profiles </br>
                    - See a profile information: </br>
                    &emsp;    - Their pictures </br>
                    &emsp;    - Their name </br>
                    &emsp;    - Their age </br>
                    &emsp;    - Their information (description, interests et general information) </br>
                    - Filter profiles depending on the age, gender and what the users are looking for</br>
                    - See who visited our profile</br>
                    - Chat with users</br>
                    - Profile management: </br>
                    &emsp;    - Log off </br>
                    &emsp;    - Delete account </br>
                    &emsp;    - Change email </br>
                    &emsp;    - Change password </br>
                    &emsp;    - Edit profile (pictures, name, age, information) </br>
                    &emsp;    - Add a user to favorites </br>
                    &emsp;    - Block a user </br>
                    </br>
                    👉 Want to learn more? Check the GitHub repository for a technical explanation of the project.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["axios", "express", "dotenv"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Language",
                        language: ["React"],
                        packages: ["axios", "redux toolkits", "react router dom", "react leaflet", "sass"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Language",
                        language: ["noSQL"],
                        packages: ["Firebase Authentication", "Firestore"]
                    },
                    deployment: {
                        title: "DEPLOYMENT",
                        frontend: "/",
                        backend: "Firebase"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/meeet-react-js"
            }
        },
        spotify98: {
            id: 4,
            FR: {
                title: "🎵 Spotify 98 💾",
                language: "REACT",
                images: ["https://iili.io/J9rLex1.png"],
                description: {
                    title: "Description",
                    description_short: "Spotify 98 est une application web de streaming de musique qui imagine ce que rendrait Spotify sur Windows 98.",
                    description: `Spotify 98 est une application Web reprenant la maquette Figma de Gustavo Luz. Cette maquette imagine ce que rendrait Spotify, l’application de streaming de musique, si elle était sortie sur Windows 98. Elle m’a tellement inspirée que j’ai décidé de l’adapter en un projet web entièrement fonctionnel. </br> 
                    Sur Spotify 98, l’utilisateur se connecte à son compte Spotify et profite de fonctionnalités telles que la lecture de musique, l’accès à la création, modification et utilisation de ses playlists, d’avoir accès à sa bibliothèque et à la recherche d’artistes et de chansons.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `- Formulaire d’inscription et de connexion avec l’API de Spotify </br>
                    - Gestion de tokens </br>
                    - Section latérale avec la navigation: </br>
                    &emsp;    - Page d’accueil </br>
                    &emsp;    - Recherche </br>
                    &emsp;    - Bibliothèque </br>
                    &emsp;    - Création de playlist </br>
                    &emsp;    - Titres aimés </br>
                    &emsp;    - Podcasts enregistrés </br>
                    &emsp;    - Playlists enregistrées </br>
                    - Section principale avec le contenu de la navigation </br>
                    - Lecteur de musique avec plusieurs fonctionnalités: </br>
                    &emsp;    - Lecture/Pause </br>
                    &emsp;    - Skip </br>
                    &emsp;    - Lecture en boucle/aléatoire </br>
                    &emsp;    - Barre de progression </br>
                    &emsp;    - Barre de gestion du volume </br>
                    </br>
                    👉 Envie d’en savoir plus ? Accédez au dépôt GitHub pour une explication technique du projet.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["react", "spotify sdk", "nodejs"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["HTML/SCSS","React", "TypeScript"],
                        packages: ["/"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["Node.js","Express", "Spotify SDK"],
                        packages: ["/"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "/",
                        backend: "/"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/spotify-98/tree/main"
            },
            EN: {
                title: "🎵 Spotify 98 💾",
                language: "REACT",
                images: ["https://iili.io/J9rLex1.png"],
                description: {
                    title: "Description",
                    description_short: "Spotify 98 is a music streaming web application which imagines what Spotify would look like in Windows 98.",
                    description: `Spotify 98 is a web application that replicates Gustavo Luz's Figma design. This design envisions what Spotify, the music streaming application, would look like if it were released on Windows 98. It inspired me so much that I decided to adapt it into a fully functional web project. </br>
                    On Spotify 98, the user logs into their Spotify account and enjoys features such as music playback, access to creating, editing, and using playlists, accessing their library, and searching for artists and songs.`
                },
                functionalities: {
                    title: "Functionalities",
                    functionalities: `- Sign up and sign in form with the Spotify API </br>
                    - Token management </br>
                    - Sidebar with navigation: </br>
                    &emsp;    - Home page</br>
                    &emsp;    - Search </br>
                    &emsp;    - Library </br>
                    &emsp;    - Playlist creation </br>
                    &emsp;    - Liked tracks </br>
                    &emsp;    - Saved podcasts </br>
                    &emsp;    - Saved playlists </br>
                    - Main section with the content of the navigation </br>
                    - Music player with multiple features:  </br>
                    &emsp;    - Play/Pause </br>
                    &emsp;    - Skip </br>
                    &emsp;    - Loop/Shuffle play  </br>
                    &emsp;    - Progress bar </br>
                    &emsp;    - Volume control bar </br>
                    </br>
                    👉 Want to learn more? Access the GitHub repository for a technical explanation of the project.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["react", "spotify sdk", "nodejs"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Language",
                        language: ["HTML/SCSS","React", "TypeScript"],
                        packages: []
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Language",
                        language: ["Node.js","Express", "Spotify SDK"],
                        packages: []
                    },
                    deployment: {
                        title: "DEPLOYMENT",
                        frontend: "/",
                        backend: "/"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/spotify-98/tree/main"
            },
        },
        gazouilleur: {
            id: 5,
            FR: {
                title: "🐦 Gazouilleur 📱",
                language: "ANGULAR",
                images: ["https://emojigraph.org/media/social/bird_1f426.png"],
                description: {
                    title: "Description",
                    description_short: "Gazouilleur est un réseau social unique en son genre qui permet de faire des tweets ! Euh... Gazouillis...",
                    description: `Gazouilleur est un réseau social inspiré de X (anciennement Twitter), et est mon projet de fin de formation avec Zenika. J’ai repris ce projet afin de le corriger et l’améliorer, car j’ai toujours eu envie de créer un réseau social, même fictif. </br> 
                    Avec Gazouilleur, l’utilisateur peut créer un compte et profiter de fonctionnalités telles que la création et suppression de Gazouillis, commenter et aimer d’autres Gazouillis et suivre des Gazouilleurs.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `- Formulaire d’inscription et de connexion </br>
                    - Gestion de tokens </br>
                    - Page d’accueil: </br>
                        &emsp;    - Création d’un Gazouilli </br>
                        &emsp;    - Fil de Gazouillis: </br>
                        &emsp;&emsp;&rarr; Commentaires </br>
                        &emsp;&emsp;&rarr; Likes </br>
                        &emsp;    - Commenter un Gazouilli </br>
                    - Section de profil: </br>
                        &emsp;    - Modifier le nom d’utilisateur </br>
                        &emsp;    - Modifier le mail et mot de passe </br>
                        &emsp;    - Nombre de Likes </br>
                        &emsp;    - Nombre de Gazouilleurs suivis </br>
                    </br>
                    👉 Envie d’en savoir plus ? Accédez au dépôt GitHub pour une explication technique du projet.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["angular", "typescript", "nestjs", "docker"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["HTML/SCSS","Angular", "TypeScript"],
                        packages: ["/"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["NestJS", "MongoDB", "Swagger", "Docker"],
                        packages: ["/"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "/",
                        backend: "/"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/gazouilleur"
            },
            EN: {
                title: "🐦 Gazouilleur 📱",
                language: "ANGULAR",
                images: ["https://emojigraph.org/media/social/bird_1f426.png"],
                description: {
                    title: "Description",
                    description_short: `"Gazouilleur" is a one-of-a-kind social network that allows you to make tweets! Uh... "Gazouillis"...`,
                    description: `"Gazouilleur" is a social network inspired by X (formerly Twitter), and it is my end-of-training project with Zenika. I took up this project to correct and improve it, as I have always wanted to create a social network, even a fictional one.
                    <br/>
                    With Gazouilleur, the user can create an account and enjoy features such as creating "Gazouillis," commenting on and liking other "Gazouillis," and following "Gazouilleurs".`
                },
                functionalities: {
                    title: "Functionalities",
                    functionalities: `- Registration and login form </br>
                    - Token management </br>
                    - Homepage: </br>
                        &emsp;    - Create a "Gazouilli" </br>
                        &emsp;    - "Gazouillis" feed: </br>
                        &emsp;&emsp;&rarr; - Comments </br>
                        &emsp;&emsp;&rarr; - Likes </br>
                        &emsp;    - Comment on a "Gazouilli" </br>
                    - Profile section: </br>
                        &emsp; - Edit username </br>
                        &emsp; - Edit email and password </br>
                        &emsp; - Number of likes </br>
                        &emsp; - Number of followed Gazouilleurs </br>
                    </br>
                    👉 Want to learn more? Access the GitHub repository for a technical explanation of the project.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["angular", "typescript", "nestjs", "mongodb", "docker"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Language",
                        language: ["HTML/SCSS","Angular", "TypeScript"],
                        packages: ["/"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Language",
                        language: ["NestJS", "MongoDB", "Swagger", "Docker"],
                        packages: ["/"]
                    },
                    deployment: {
                        title: "DEPLOYMENT",
                        frontend: "/",
                        backend: "/"
                    }
                },
                link: "/",
                github: "https://github.com/naomi-lgt/gazouilleur"
            },
        }
    },
    done: {
        museek: {
            id: 2,
            FR: {
                title: "🎵 Museek 2023 🎶",
                language: "JAVASCRIPT",
                images: ["https://iili.io/Jx6hfa9.png", "https://iili.io/Jx6XTdX.png"],
                description: {
                    title: "Description",
                    description_short: "Museek est un projet qui permet de rechercher les paroles de vos musiques préférées ! Entièrement fait avec JavaScript et alimenté par l'API de Genius et Node.js.",
                    description: `Museek (mélange de « music » et « seek » 🧠) est un site qui vous permet de rechercher les paroles de vos chansons préférées et d’en apprendre plus sur ces chansons ! </br>
                    Le principe est très simple. On recherche un artiste ou une chanson, et on a ensuite accès aux paroles et à la description de la chanson (l’ordre dépend si c’est un affichage mobile ou bureau 😉) dont le lien YouTube pour regarder le clip.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `• Toute l’application repose autour de la barre de recherche. L’utilisateur y écrit le nom d’une chanson ou le nom d’un artiste. </br>
                    • Un menu déroulant avec plusieurs propositions s’ouvre lorsque la recherche est soumise </br>
                    • Quand l’utilisateur clique sur une proposition, alors un loader se lance le temps que la requête vers l’API de Genius soit effectuée. </br>
                    • Enfin, le résultat s’affiche en deux catégories: </br>
                    &emsp; • Les paroles contiennent des annotations qui redirigent vers le site de Genius. Ces annotations proviennent de l’API de Genius. </br>
                    &emsp; • La section comprenant des informations sur la chanson est divisée en trois parties: </br>
                    &emsp; &emsp; • “About this song” contient la pochette de la chanson, avec son titre, l’artiste et de quel album elle provient. </br>
                    &emsp; &emsp; • “Song summary” contient la description du site de Genius de la chanson, comprenant des annotations. </br>
                    &emsp; &emsp; • “Stream this song” contient un iframe avec le clip YouTube pour la chanson. </br>
                    </br>
                    👨🏻‍💻 Côté technique</br>
                    </br>
                    Museek est un projet entièrement réalisé en pur JavaScript et alimenté par l’API de Genius pour toutes les données des chansons. </br>
                    </br>
                    👨🏻‍🎨 Le front est stylisé avec du CSS pur et l’affichage est géré avec une media query qui détecte si l’écran est un mobile ou un bureau. De plus, le fichier JavaScript côté front gère toutes les informations renseignées par l’utilisateur afin de faire les requêtes au serveur. </br>
                    </br>
                    👨🏻‍🔬 Le back est un serveur Express sous Node.js qui va récupérer les requêtes du front et les envoyer à l’API de Genius, avant de renvoyer les résultats au front. </br> 
                    </br> 
                    👉 Envie d’en savoir plus ? Accédez au dépôt GitHub pour une explication technique du projet.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["node.js", "express", "axios", "genius api"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["JavaScript"],
                        packages: ["axios"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["Node.js"],
                        packages: ["axios", "express", "genius api"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "Netlify",
                        backend: "Render"
                    }
                },
                link: "https://museek2023.netlify.app",
                github: "https://github.com/naomi-lgt/Museek-2023"
            },
            EN: {
                title: "🎵 Museek 2023 🎶",
                language: "JAVASCRIPT",
                images: ["https://iili.io/Jx6hfa9.png", "https://iili.io/Jx6XTdX.png"],
                description: {
                    title: "Description",
                    description_short: "Museek is a project allowing you to search for your favorite music lyrics! Entirely made with JavaScript and powered by the Genius API and Node.js.",
                    description: `Museek (mix of « music » and « seek » 🧠) is a website allowing you to search for for your favorite music lyrics and to learn more about these songs! </br>
                    The idea is very simple. We search for an artist or a song, and then we have access to the lyrics and to the song description (the order depends if it's a mobile view or desktop 😉) including the YouTube link to watch the video.`
                },
                functionalities: {
                    title: "Functionalities",
                    functionalities: `• The whole application revolves around the search bar. The user types in a song name or an artist name. </br>
                    • A scrolling menu with multiple choices opens when the query is submitted </br>
                    • When the user clicks on a choice, a loader appears while the request to the Genius API is done. </br>
                    • Finally, the result is displayed in two categories: </br>
                    &emsp; • The lyrics contain facts redirecting to the Genius website. These facts comme from the Genius API. </br>
                    &emsp; • The section with the song information is divided in three parts: </br>
                    &emsp; &emsp; • “About this song” contains the cover of the song, its title, the artist and from which album the song is from. </br>
                    &emsp; &emsp; • “Song summary” contains the Genius description of the song with facts. </br>
                    &emsp; &emsp; • “Stream this song” contains an iframe with the YouTube video for the song. </br>
                    </br>
                    👨🏻‍💻 Spec wise</br>
                    </br>
                    Museek is a project entirely made in pure JavaScript and powered by the Genius API for all the song data.</br>
                    </br>
                    👨🏻‍🎨 The front-end is styled with pure CSS and the layout is handled with a media query which detects if the screen is a mobile ou a desktop. Moreover, the front side JavaScript file handles all the user information in order to fetch the data from the server.</br>
                    </br>
                    👨🏻‍🔬 The back-end is an Express server on Node.js which is going to get the requests from the front and send them to the Genius API, before seding back the results to the front. </br> 
                    </br>
                    👉 Want to learn more? Check the GitHub repository for a technical explanation of the project. `
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["axios", "express", "dotenv", "genius api"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Language",
                        language: ["JavaScript"],
                        packages: ["axios"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Language",
                        language: ["Node.js"],
                        packages: ["axios", "express"]
                    },
                    deployment: {
                        title: "DEPLOYMENT",
                        frontend: "Netlify",
                        backend: "Render"
                    }
                },
                link: "https://museek2023.netlify.app",
                github: "https://github.com/naomi-lgt/Museek-2023"
            }
        },
        villarosa: {
            id: 3,
            FR: {
                title: "🧑‍🍳  Villa Rosa 💐",
                language: "REACT",
                images: ["https://iili.io/jCCddl.png", "https://iili.io/jCBsJp.png", "https://iili.io/jCBL5N.png", "https://webm.red/view/8HzZ.webm"],
                description: {
                    title: "Description",
                    description_short: "Villa Rosa est un projet de site vitrine one page d'un faux restaurant que j'ai imaginé.",
                    description: `Villa Rosa est un projet de site vitrine one page d'un faux restaurant que j'ai imaginé. Ce projet créé avec React va droit au but et a été imaginé comme un moyen pour promouvoir un restaurant appelé Villa Rosa. </br> 
                    Le site est divisé en trois catégories: Home, Services et Contact. Il utilise aussi un carousel SwiperJS et peut être navigué via un header.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `👨🏻‍🎨 Front-end </br>
                    </br>
                    Afin d'arriver à mon but de site one page, j'ai séparé chaque catégorie (Home, Services et Contact) en composants avec une hauteur de 100vh. J'ai aussi utilisé le module React Scroll pour gérer le header et le défilement fluide vers les catégories.</br>
                    </br>
                    Dans le composant Home, j'ai utilisé SwiperJS pour créer un carousel empilé avec des images que j'ai trouvées sur Unsplash.</br>
                    </br>
                    Dans le composant Services, j'ai fait une gille responsive avec les icônes des services du restaurant. Quand une icône est cliquée, un onglet s'ouvre avec le nom et la description du service. De plus, j'ai créé un hook custom dans useOutsideClick.js afin de fermer l'onglet quand un autre est cliqué ou si l'utilisateur clique en dehors de la grille. </br>
                    </br>
                    👉 Envie d’en savoir plus ? Accédez au dépôt GitHub pour une explication technique du projet.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["react", "swiper js", "react scroll"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["React"],
                        packages: ["react", "swiper js", "react scroll"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["/"],
                        packages: ["/"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "Netlify",
                        backend: "/"
                    }
                },
                link: "https://villarosa.netlify.app",
                github: "https://github.com/naomi-lgt/villa-rosa"
            },
            EN: {
                title: "🧑‍🍳  Villa Rosa 💐",
                language: "REACT",
                images: ["https://iili.io/jCCddl.png", "https://iili.io/jCBsJp.png", "https://iili.io/jCBL5N.png", "https://webm.red/view/8HzZ.webm"],
                description: {
                    title: "Description",
                    description_short: "Villa Rosa is a project for a one page mock restaurant showcase website that I imagined.",
                    description: `Villa Rosa is a project for a one page mock restaurant showcase website that I imagined. This straight forward project was made in React and was imagined as a way to promote a restaurant called Villa Rosa. </br> 
                    The website is divided in three categories: Home, Services and Contact. It also uses a SwiperJS carousel and can be browsed with a header.`
                },
                functionalities: {
                    title: "Fonctionnalités",
                    functionalities: `👨🏻‍🎨 Front-end </br>
                    </br>
                    In order to achieve my goal of making a one page website, I seperated each category (Home, Services and Contact) in a component with a height of 100vh. I also used a module called React Scroll to handle the header and the smooth scrolling to the categories.</br>
                    </br>
                    In the Home component, I used SwiperJS to create a stacked carousel with images I found on Unsplash.</br>
                    </br>
                    In the Services component, I made a responsive grid with the restaurant's services icons. When an icon is clicked, a tab opens with the name and description of the service. Moreover, I made a custom hook in useOutsideClick.js to close the tab when another one is clicked or if the user clicks outside the grid. </br>
                    </br>
                    👉 Want to learn more? Check the GitHub repository for a technical explanation of the project.`
                },
                technos: {
                    title: "Technologies",
                    technos_main: ["react", "swiper js", "react scroll"],
                    frontend: {
                        title: "FRONT-END",
                        language_title: "Langage",
                        language: ["React"],
                        packages: ["react", "swiper js", "react scroll"]
                    },
                    backend: {
                        title: "BACK-END",
                        language_title: "Langage",
                        language: ["/"],
                        packages: ["/"]
                    },
                    deployment: {
                        title: "DÉPLOIEMENT",
                        frontend: "Netlify",
                        backend: "/"
                    }
                },
                link: "https://villarosa.netlify.app",
                github: "https://github.com/naomi-lgt/villa-rosa"
            }
        },
    },
}

export default projectsData;