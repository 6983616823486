import { useContext } from 'react';
import { Context } from '../../context/langContext'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import DOMPurify from 'dompurify';
import data from '../../data/translations';

import './WhoAmI.scss';
import photocv from '../../assets/photocv.png'
import cv_fr from '../../assets/FR-CV-Naomi-Legentil.pdf'
import cv_en from '../../assets/EN-CV-Naomi-Legentil.pdf'
import home from '../../assets/home.svg';

const WhoAmI = () => {
    const { lang } = useContext(Context);

    return (
        <motion.div className="whoami-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            <div className="bio-container">
                <h2>{data[lang].whoami.title}</h2>
                <div className="bio-group">
                    <div className="bio-avatar-cv">
                        <img className="bio-avatar" src={photocv} />
                        <a className="bio-cv" href={(lang === 'FR') ? cv_fr : cv_en} download="CV Naomi Legentil">
                            {data[lang].whoami.cv}
                        </a>
                    </div>
                    <div className="bio-summary">
                        {data[lang].whoami.bio}
                    </div>
                </div>
            </div>
            <div className="formation-container">
                <h2>{data[lang].whoami.formation}</h2>
                {data[lang].whoami.formations_array.map((formation, i) => {
                    return (
                        <div key={i} className="formation-summary">
                            <div className="formation-header">
                                <h3>{formation.end}</h3>
                                <a href={formation.url} target="_blank" rel="noreferrer">
                                    <img className="formation-logo" src={formation.logo} alt={formation.name} />
                                </a>
                            </div>
                            <div className="formation-info-group">
                                <div className="linebreak"></div>
                                <div className="formation-info">
                                    <a href={formation.url} target="_blank" rel="noreferrer">
                                        <h4>{formation.name}</h4>
                                    </a>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formation.description) }}>
                                    </p>
                                </div>
                            </div>
                            <h3>{formation.beginning}</h3>
                        </div>
                    )
                })}
            </div>
            <nav className="home-arrow arrow-whoami">
                <Link to="/">
                    <img src={home} alt="Retourner à l'accueil" />
                </Link>
            </nav>
        </motion.div>
    )
};

export default WhoAmI;
