import { useEffect, useState, useContext } from 'react';
import { Context } from '../../context/langContext'
import { NavLink } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector/LanguageSelector.js';
import data from '../../data/translations';

import france from '../../assets/france.png';
import uk from '../../assets/uk.png';
import './Header.scss';

const Header = () => {
    const { lang, toggleLang } = useContext(Context);

    // useState for the mobile burger icon
    const [showMenu, setShowMenu] = useState(false);
    // useState for when the mobile menu
    const [isActiveMenu, setActiveMenu] = useState(false);
    // useState to set the language
    const [selectLanguage, setSelectLanguage] = useState(false);
    // useState to detect if the viewport is less than 768px wide
    const [isPhone, setPhone] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setPhone(window.innerWidth < 768);
    }

    const phoneMenuHandler = () => {
        setShowMenu(!showMenu)
    }

    let menu;

    // When the burger menu is clicked
    if (showMenu) {
        menu =
            <div className="header-mobile-burger">
                <section className="header-link link-projects" onClick={phoneMenuHandler}><NavLink to="/projects" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.projects}</NavLink></section>
                <section className="header-link link-whoami" onClick={phoneMenuHandler}><NavLink to="/whoami" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.whoami}</NavLink></section>
                <section className="header-link link-technos" onClick={phoneMenuHandler}><NavLink to="/technos" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.technos}</NavLink></section>
                <img className="header-language" src={france} alt="Changer de langue" onClick={() => setSelectLanguage(!selectLanguage)} />
                {selectLanguage &&
                    <LanguageSelector setSelectLanguage={setSelectLanguage} selectLanguage={selectLanguage} toggleLang={toggleLang} lang={lang} />
                }
            </div>
    };

    let phoneMenu =
        <>
            <svg className={`burger-icon${showMenu ? ` burger-clicked` : ``}`} onClick={phoneMenuHandler} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                    fill="currentColor"
                />
            </svg>
            <nav className={`header-container${showMenu ? ` header-clicked` : ` header-closed`}`}>

                {menu}
            </nav>
        </>
        ;

    let regularMenu =
        <nav className="header-container">
            <section className="header-link link-projects"><NavLink to="/projects" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.projects}</NavLink></section>
            <section className="header-link link-whoami"><NavLink to="/whoami" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.whoami}</NavLink></section>
            <section className="header-link link-technos"><NavLink to="/technos" className={({ isActive }) => isActive ? 'background' : ''}>{data[lang].header.technos}</NavLink></section>
            <img className="header-language" src={(lang === 'FR') ? france : uk} alt="Changer de langue" onClick={() => setSelectLanguage(!selectLanguage)} />
            {selectLanguage &&
                <LanguageSelector setSelectLanguage={setSelectLanguage} selectLanguage={selectLanguage} toggleLang={toggleLang} lang={lang} />
            }
        </nav>
        ;


    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    })

    return (
        <>
            {isPhone ? phoneMenu : regularMenu}
        </>
    )
};

export default Header;
