import { useState, useContext } from 'react';
import { Context } from '../../context/langContext'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import data from '../../data/translations';
import LanguageSelector from '../LanguageSelector/LanguageSelector.js';

import './Home.scss';

import photocv from '../../assets/photocv.png';
import linkedin from '../../assets/linkedin.png';
import github from '../../assets/github.png';
import mail from '../../assets/mail.png';
import downarrow from '../../assets/downarrow.png';
import france from '../../assets/france.png';
import uk from '../../assets/uk.png';
import roombackground from '../../assets/roombackground.png';

const Home = () => {
    const { lang, toggleLang } = useContext(Context);

    const [selectLanguage, setSelectLanguage] = useState(false);

    return (
        <motion.div className="home-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: -1000, opacity: 0, transition: { duration: 0.5 } }}
        >
            <img className="home-language" src={(lang === 'FR') ? france : uk} alt="Changer de langue" onClick={() => setSelectLanguage(!selectLanguage)} />
            {selectLanguage &&
                <LanguageSelector setSelectLanguage={setSelectLanguage} selectLanguage={selectLanguage} toggleLang={toggleLang} lang={lang} />
            }
            <div className="home-hero">
                <img className="home-hero hero-picture" src={photocv} alt="Photo Naomi Legentil"/>
                <h1 className="home-hero hero-welcome">{data[lang].home.welcome}</h1>
                <span className="home-hero hero-description">{data[lang].home.description}</span>
                <section className="home-hero hero-contact">
                    <a href="https://www.linkedin.com/in/naomi-legentil-51798a265/" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="Contactez-moi sur Linkedin" />
                    </a>
                    <a href="https://github.com/naomi-lgt" target="_blank" rel="noreferrer">
                        <img src={github} alt="Mon profil GitHub" />
                    </a>
                    <a href="mailto:naomi.legentil@outlook.fr" target="_blank" rel="noreferrer">
                        <img src={mail} alt="Contactez-moi par mail" />
                    </a>
                </section>
            </div>
            <div className="home-scroll">
                <span className="home-scroll scroll-projects">{data[lang].home.look}</span>
                <Link to="/projects">
                    <img className="home-scroll scroll-arrow" src={downarrow} alt="Naviguez vers les autres catégories" />
                </Link>
            </div>
            <div className="home-background-container">
                <img className="home-background" src={roombackground} alt="" />
                <div className="home-background-blur"></div>
            </div>
        </motion.div>
    )
};

export default Home;
